<script setup>
import VueApexCharts from "vue3-apexcharts";
import { debounce } from "lodash-es";
import abbreviate from "number-abbreviate";
import moment from "moment";

const props = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const formatTooltip = (date, value) => `
  <div class="custom-tooltip p-2 "> 
    <div class="text-gray-600 font-medium">
      ${moment(date).format("ll")}
    </div>
    <div class='font-bold'>
      ${value.toLocaleString()} 
    </div>
  </div>
`;

const seriesData = computed(
  () => props.data?.map((e) => [new Date(e.day), e.clicks]) || []
);

const series = ref([
  {
    name: "clicks",
    data: seriesData.value,
  },
]);

const chartOptions = ref({
  chart: {
    id: "day-summary",
    type: "line",
    zoom: {
      autoScaleYaxis: true,
    },
  },
  title: {
    text: "Daily Summary",
    margin: 20,
    offsetX: 10,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    colors: ["#0059ff"],
    width: 3,
  },
  markers: {
    size: 2,
    colors: "#0059ff", // set the fill color of the markers
  },
  fill: {
    type: "gradient",
    colors: ["#0059ff"],
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100],
    },
  },
  xaxis: {
    type: "datetime",
    tickAmount: 3,
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    min: 0,
    labels: {
      formatter: function (value, opts) {
        return Math.round(value);
      },
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      show: false,
      formatter: function (value) {
        return abbreviate(value, 1);
      },
    },
    x: {
      show: false,
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const date = w.globals.seriesX[seriesIndex][dataPointIndex];
      const value = series[seriesIndex][dataPointIndex];
      return formatTooltip(date, value);
    },
  },
  grid: {
    show: true,
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
});

const chartRef = ref(null);

// Debounce the update function
const debouncedUpdateData = debounce(() => {
  chartRef.value?.updateSeries([
    {
      name: "clicks",
      data: seriesData.value,
    },
  ]);
}, 300);

watch(() => props.data, debouncedUpdateData, { immediate: true });

// Cleanup on unmount
onUnmounted(() => {
  debouncedUpdateData.cancel();
});
</script>
<template>
  <div class="h-full p-5">
    <VueApexCharts
      ref="chartRef"
      height="380"
      width="100%"
      type="area"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<style></style>
