<!-- TODO: fix console error -->
<script setup>
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
import { debounce } from "lodash-es";
import abbreviate from "number-abbreviate";

const props = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const formatTooltip = (month, value) => `
  <div class="custom-tooltip p-2">
    <div class="text-gray-600 font-medium">
      ${month}
    </div>
    <div class='font-bold'>
      ${Math.round((value / 100) * props.totalClicks).toLocaleString()}
      (${value.toFixed(1)}%)
    </div>
  </div>
`;

const allMonths = Array.from({ length: 12 }, (_, index) => {
  return moment().startOf("year").add(index, "months").format("MMM");
});

const seriesData = computed(() => {
  return allMonths.map((month) => {
    const dataForMonth = props.data.find((e) => moment(e.month).format("MMM") === month);
    return dataForMonth ? (dataForMonth.clicks / props.totalClicks) * 100 : 0;
  });
});

const series = ref([
  {
    name: "clicks",
    data: seriesData.value,
  },
]);
const chartOptions = ref({
  chart: {
    id: "monthly-summary",
    type: "bar",
  },
  title: {
    text: "Monthly Summary",
    margin: 20,
    offsetX: 10,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: "rounded",
      borderRadius: 5,
      // columnWidth: "20%",
      borderRadiusApplication: "end",
      colors: {
        ranges: [
          {
            from: 0,
            to: 100,
            color: "#0059ff",
          },
        ],
        backgroundBarColors: ["#eee"],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 9,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: allMonths,
    fill: {
      opacity: 1,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    max: 100,
    labels: {
      formatter: function (value, opts) {
        return value + "%";
      },
    },
    tickAmount: 5,
  },
  tooltip: {
    x: {
      show: true,
    },

    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const month = w.globals.labels[dataPointIndex];
      const value = series[seriesIndex][dataPointIndex];
      return formatTooltip(month, value);
    },
  },

  grid: {
    show: false,
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
});

const chartRef = ref(null);

// Debounce the update function
const debouncedUpdateData = debounce(() => {
  chartRef.value?.updateSeries([
    {
      name: "clicks",
      data: seriesData.value,
    },
  ]);
}, 300);

watch(() => props.data, debouncedUpdateData, { immediate: true });

// Cleanup on unmount
onUnmounted(() => {
  debouncedUpdateData.cancel();
});
</script>
<template>
  <div class="h-full p-5">
    <VueApexCharts
      ref="chartRef"
      height="380"
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
